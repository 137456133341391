import { BasicPayManager, type BasicPayManagerConstructor, type AbstractMethods } from '../BasicPayManager'
import { debuggerLog } from '../../utils'
import defaultConfig from '../../config/apple_pay'
import { Trade_PayToolKit, Trade_PayLibs } from '@shein-aidc/types-trade'
import { MonitorReportAbnormal, MonitorReportBusiness } from '../helpers/MonitorReport'
import { ThreeDSecureManager } from '../helpers/ThreeDSecureManager'
import { CHECKOUT_TYPE, PAYMENT_ACTION_TYPE, PRODUCT_TYPE } from '../../../types'
interface ApplePayValidateMerchantEvent extends Event {
  /**
   * The URL your server must use to validate itself and obtain a merchant session object.
   */
  readonly validationURL: string;
}

/**
 * Apple Pay相关接口
 * @TODO kafkaInfo相关是否需要
 */
interface IApplePayConfig {
  applePayCodeList?: string[]
  cardLogoConfig?: Record<string, {
    logo: string
    name: string
    sdk_version: string
  }>
  applePayTokenChannel?: string[]
  mapChannelToApplePaySupport?: Record<string, {
    merchantCapabilities: string[]
  }>
  applePayCashShow?: string
}

interface IApplePaySessionParams {
  countryCode: string
  currencyCode: string
  merchantCapabilities: string[]
  supportedNetworks: string[]
  total: {
    label: string
    amount: string
    type: string
  }
  lineItems?: {
    label: string
    amount: string
  }[]
}

// interface IPriceDetailItem {
//   type: string
//   local_name: string
//   amount: string
//   price_with_symbol: string
//   origin_price_with_symbol?: string
//   show: number
//   show_type: string[]
//   display_type?: string
//   price_sort?: string
//   sort?: number
//   sub_price_details?: IPriceDetailItem[]
//   display_type_for_apple_pay?: string
// }


interface IOrderInfo {
  billno: string
  currency_code: string
  countryCode?: string
  payment_method: string
  totalPriceAmount: string
  totalPriceWithGovTaxAmount?: string
  sorted_price?: Trade_PayToolKit.SortedPriceItem[],
}

interface IApplePayCardTypeConfig {
  cardLogoList?: string[]
  apple_google_support_config_list?: Trade_PayToolKit.PaymentInfoItem['apple_google_support_config_list']
}

interface IApplePayParams {
  order: IOrderInfo
  // applePay前置路由信息
  preRoutingInfo?: {
    applePayMerchantId?: string
    appleCountryCode?: string
  },
  cardLogoList?: string[]
  cardTypeConfig?: IApplePayCardTypeConfig
}

interface IApplePayAuthorizeMerchantParams {
  validateUrl: string
  merchantIdentifier: string
}

interface IApplePayAuthorizeMerchantRes {
  merchantSession: any
}

interface IApplePayInfoParams {
  paymentCode: string
  billno: string
}

interface IApplePayInfo {
  applepayKey: string
  appleCountryCode: string
}

interface IApplePayInfoRes {
  code: string
  info: IApplePayInfo
}

interface ISchttpEventHandle {
  success: (data: any) => void
  error: (error: any) => void
}

export interface ApplePayManagerConstructor extends BasicPayManagerConstructor { }

export class ApplePayManager extends BasicPayManager implements AbstractMethods {

  public static PAYMENT_CODE = ''

  private static payConfig: IApplePayConfig = {}

  private static supportVersion = 3
  private applePayInfo: IApplePayInfo | null = null

  private session

  private static failFns: any[] = []
  private static openDialogMethod: (({ handleOpen, handleClose }) => () => void) | null = null

  constructor(params: ApplePayManagerConstructor) {
    super(params)
  }

  async applePayAuthorizeMerchant(params: IApplePayAuthorizeMerchantParams) {
    const result = await this.appConfigs.$schttp<IApplePayAuthorizeMerchantRes>({
      baseURL: this.appConfigs.$envs?.langPath,
      url: '/api/pay/applepayauthorizemerchant/get',
      method: 'POST',
      data: {
        url: params.validateUrl,
        hostname: window.location.hostname,
        merchantIdentifier: params.merchantIdentifier,
      },
    })
    return (result && result.data) || {}
  }

  getApplePayInfoByCode(params: IApplePayInfoParams, handles: ISchttpEventHandle) {
    return this.appConfigs.$schttpSync<IApplePayInfoRes>({
      method: 'POST',
      url: '/api/pay/applepayInfo/get',
      baseURL: this.appConfigs.$envs?.langPath,
      data: {
        billno: params.billno,
        paymentCode: params.paymentCode,
      },
      success: (res) => {
        handles.success(res)
      },
      error: (err) => {
        handles.error(err)
      },
    })
  }

  onHandlePaymentFail(err?: any) {
    ApplePayManager.failFns.forEach((fn) => {
      fn?.(err)
    })
    // 执行完成后清空回调函数
    ApplePayManager.failFns = []
  }

  cancelPaymentSession() {
    if (this.session !== null) {
      this.session.abort()
    }
    this.onCancel?.()
  }

  static isApplePay(paymentCode: string) {
    const applePayMethods = this.payConfig.applePayCodeList || defaultConfig.applePayChannel
    return applePayMethods.includes(paymentCode)
  }

  static isSupportApplePay() {
    try {
      return (window as any).ApplePaySession && (window as any).ApplePaySession.canMakePayments?.()
    } catch (e) {
      console.error(e)
      // TODO 处理不支持Apple Pay的情况
      MonitorReportAbnormal.metric({
        scene: 'apple_pay_is_not_supported_catch',
        extraTags: {
          failure_type: 'sdk',
        },
        extraParams: {
          client_url: '/third/sdk/error',
          error_msg: (e as any)?.message || '',
          error_stack: JSON.stringify(e || {}),
          payment_action_type: PAYMENT_ACTION_TYPE.APPLE_PAY,
        },
      })
      return false
    }
  }

  private static getSupportedCurrentSdkVersion() {
    let version = 3
    if (this.isSupportApplePay()) {
      version = 14
      try {
        // 这里防止以后safari耍流氓，不初始化session不让调Apple Pay方法的情况报错卡住代码
        while (!(window as any).ApplePaySession.supportsVersion?.(version)) {
          version--
          // 增加一个保护，防止死循环
          if (version == 3) {
            break
          }
        }
      } catch (e) {
        version = 3
      }
      this.supportVersion = version
    }
  }

  getLineItem(priceDetailItem: Trade_PayToolKit.SortedPriceItem) {
    const { local_name, amount, show_type } = priceDetailItem
    const isNegative = show_type?.includes('negative_price')
    return {
      label: local_name,
      amount: isNegative ? `-${amount}` : amount,
    }
  }

  buildApplePayPriceDetail(sortedPriceArray: Trade_PayToolKit.SortedPriceItem[], scene?: Trade_PayLibs.PRODUCT_TYPE) {
    const priceDetail: { label: string, amount: string }[] = []
    // 过滤掉不需要展示的价格
    sortedPriceArray = sortedPriceArray.filter?.(item => {
      return item.show == 1
    })

    if (ApplePayManager.payConfig.applePayCashShow === 'Hide') {
      for (const sortPrice of sortedPriceArray) {
        if (sortPrice.display_type_for_apple_pay == '1' && sortPrice.sub_price_details?.length) {
          for (const subPrice of sortPrice.sub_price_details) {
            priceDetail.push(this.getLineItem(subPrice))
          }
        } else {
          priceDetail.push(this.getLineItem(sortPrice))
        }
      }
    } else if (ApplePayManager.payConfig.applePayCashShow === 'Show2') {
      let type = 'origin'
      if (scene === PRODUCT_TYPE.PAID_MEMBERSHIP) {
        type = 'primePrice'
      } else if (scene === PRODUCT_TYPE.PAID_SHEIN_SAVER) {
        type = 'saveCardPrice'
      }
      const originPriceDetail = sortedPriceArray.find(item => item.type === type)
      if (originPriceDetail) {
        priceDetail.push(this.getLineItem(originPriceDetail))
      }
    }
    return priceDetail
  }

  static getApplePaySupportedNetworks = (cardLogoList?: string[], apple_google_support_config_list?: Trade_PayToolKit.PaymentInfoItem['apple_google_support_config_list']) => {
    if (!cardLogoList) return null
    const networks: string[] = []
    const { cardLogoConfig } = this.payConfig
    cardLogoList?.forEach((cardLogo) => {
      const config = apple_google_support_config_list?.find(item => item.card_type_logo === cardLogo)
      const apolloConfig = cardLogoConfig?.[cardLogo]
      if (config) {
        const { card_type, web_min_version } = config
        if (web_min_version && card_type && parseInt(web_min_version) <= this.supportVersion) {
          networks.push(card_type)
        } else if (!apple_google_support_config_list?.length && apolloConfig?.sdk_version && parseInt(apolloConfig?.sdk_version) <= this.supportVersion) {
          networks.push(apolloConfig?.name)
        }
      }
    })
    if (networks.length) {
      return networks
    } else {
      return null
    }
  }

  buildApplePaySessionParams(order: IOrderInfo, cardTypeConfig?: IApplePayCardTypeConfig): IApplePaySessionParams {
    const { applepayKey = '', appleCountryCode = '' } = this.applePayInfo || {}
    const countryCode = appleCountryCode || order.countryCode

    if (!applepayKey && !appleCountryCode) {
      MonitorReportAbnormal.metric({
        scene: 'apple_pay_build_session_params_error',
        extraTags: {
          failure_type: 'web',
          payment_code: order.payment_method,
        },
        extraParams: {
          client_url: '/web/error',
          billno: order.billno,
          description: '创建seesion参数获取失败',
          applepay_info: JSON.stringify(this.applePayInfo || {}),
          payment_action_type: PAYMENT_ACTION_TYPE.APPLE_PAY,
        },
      })
      this.onHandlePaymentFail()
    }
    let totalPriceAmount = '0'
    // 巴西政府税特殊传值逻辑 https://wiki.dotfashion.cn/pages/viewpage.action?pageId=1427084862
    if (order.totalPriceWithGovTaxAmount) {
      totalPriceAmount = order.totalPriceWithGovTaxAmount
    } else {
      totalPriceAmount = order.totalPriceAmount
    }

    if (!totalPriceAmount) {
      MonitorReportAbnormal.metric({
        scene: 'apple_pay_get_total_price_error',
        extraTags: {
          failure_type: 'web',
          payment_code: order.payment_method,
        },
        extraParams: {
          client_url: '/web/error',
          billno: order.billno,
          description: '获取总价失败',
          payment_action_type: PAYMENT_ACTION_TYPE.APPLE_PAY,
        },
      })
      this.onHandlePaymentFail()
    }

    if (!countryCode) {
      MonitorReportAbnormal.metric({
        scene: 'apple_pay_get_country_code_error',
        extraTags: {
          failure_type: 'web',
          payment_code: order.payment_method,
        },
        extraParams: {
          client_url: '/web/error',
          billno: order.billno,
          description: '获取countryCode失败',
          applepay_info: JSON.stringify(this.applePayInfo),
          payment_action_type: PAYMENT_ACTION_TYPE.APPLE_PAY,
        },
      })
    }

    const supportedNetworks = ApplePayManager.getApplePaySupportedNetworks(cardTypeConfig?.cardLogoList, cardTypeConfig?.apple_google_support_config_list)

    const request: IApplePaySessionParams = {
      countryCode: countryCode || 'US', // TODO: 需要确认
      currencyCode: order.currency_code,
      merchantCapabilities: ApplePayManager.payConfig.mapChannelToApplePaySupport?.[order.payment_method]?.merchantCapabilities ?? defaultConfig[order.payment_method]?.merchantCapabilities, // TODO: 需要确认
      supportedNetworks: supportedNetworks ?? defaultConfig[order.payment_method]?.supportedNetworks,
      total: {
        label: 'SHEIN',
        amount: totalPriceAmount,
        type: 'final',
      },
    } as IApplePaySessionParams

    if (!order.sorted_price || !order.sorted_price?.length) {
      MonitorReportAbnormal.metric({
        scene: 'apple_pay_sorted_price_empty',
        extraTags: {
          failure_type: 'web',
          payment_code: order.payment_method,
        },
        extraParams: {
          client_url: '/web/error',
          billno: order.billno,
          request: JSON.stringify(request || {}),
          payment_action_type: PAYMENT_ACTION_TYPE.APPLE_PAY,
        },
      })
    }

    const { productType } = this.bsPayConfig || {}

    const lineItems = this.buildApplePayPriceDetail(order.sorted_price || [], productType) || []

    if (lineItems.length) {
      request.lineItems = lineItems
    } else {
      MonitorReportAbnormal.metric({
        scene: 'apple_pay_line_items_empty',
        extraTags: {
          failure_type: 'web',
          payment_code: order.payment_method,
        },
        extraParams: {
          client_url: '/web/error',
          billno: order.billno,
          request: JSON.stringify(request || {}),
          payment_action_type: PAYMENT_ACTION_TYPE.APPLE_PAY,
        },
      })
    }

    return request
  }

  private handlePaymentAuthorized = async (event: any) => {
    const applePayTokenChannel = ApplePayManager.payConfig.applePayTokenChannel || []
    const formData = {
      applePayInfo: applePayTokenChannel.includes(this.unifiedPayParams.paymentCode) ?
        JSON.stringify(event.payment.token)
        : JSON.stringify(event.payment.token.paymentData),
    }

    this.updateUnifiedPayParams({ ...formData })
    this.paymentLoadingAction?.({ show: true })
    const { status, result } = await this.handleUnifiedPay()
    if (status === 'success') {
      const update = { status: (window as any).ApplePaySession.STATUS_SUCCESS }
      this.session.completePayment(update)
      this.onSuccess?.(result as any)
    } else if (status === 'continue') {
      const { action, paramList, actionUrl } = result?.info || {}
      if (action === 'render' && paramList && actionUrl) {
        ThreeDSecureManager.makeForm({
          params: paramList,
          action: actionUrl,
        })
        MonitorReportBusiness.metric({
          scene: 'apple_pay_payment_continue_render',
          extraTags: {
            payment_code: this.unifiedPayParams.paymentCode,
          },
          extraParams: {
            billno: this.unifiedPayParams.billno,
            actionUrl,
            paramList: JSON.stringify(paramList || {}),
            action,
            payment_action_type: PAYMENT_ACTION_TYPE.APPLE_PAY,
          },
        })
      } else {
        const update = { status: (window as any).ApplePaySession.STATUS_FAILURE }
        this.session.completePayment(update)
        this.onHandlePaymentFail()
        MonitorReportAbnormal.metric({
          scene: 'apple_pay_payment_continue_error',
          extraTags: {
            payment_code: this.unifiedPayParams.paymentCode,
          },
          extraParams: {
            billno: this.unifiedPayParams.billno,
            error_msg: '支付状态为continue',
            actionUrl,
            paramList: JSON.stringify(paramList || {}),
            action,
            payment_action_type: PAYMENT_ACTION_TYPE.APPLE_PAY,
          },
        })
      }
    } else {
      const update = { status: (window as any).ApplePaySession.STATUS_FAILURE }
      this.session.completePayment(update)
      this.onHandlePaymentFail()
    }
    this.paymentLoadingAction?.({ show: false })
  }

  // eslint-disable-next-line max-lines-per-function
  handleApplePayPayment(params: IApplePayParams) {
    debuggerLog('handleApplePayPayment', params)
    const { order, preRoutingInfo, cardTypeConfig } = params
    ApplePayManager.failFns.push(this.onError?.bind(this))

    const metricReport = ({
      scene,
      failure_type,
      status_code,
      extraParams,
    }: {
      scene: string
      failure_type?: 'sdk' | 'api'
      status_code?: string
      extraParams?: Record<string, any>
    }) => {
      MonitorReportAbnormal.metric({
        scene,
        extraTags: {
          failure_type: failure_type || 'sdk',
          payment_code: order.payment_method,
          status_code: status_code || '',
        },
        extraParams: Object.assign({}, { billno: order.billno, payment_action_type: PAYMENT_ACTION_TYPE.APPLE_PAY }, extraParams || {}),
      })
    }

    if (ApplePayManager.isSupportApplePay()) {
      try {
        const version = ApplePayManager.supportVersion
        if (preRoutingInfo?.applePayMerchantId && preRoutingInfo?.appleCountryCode) {
          this.applePayInfo = {
            applepayKey: preRoutingInfo.applePayMerchantId,
            appleCountryCode: preRoutingInfo.appleCountryCode,
          }
        } else {
          // @ts-ignore TODO: 需要确认apple pay配置的获取方式
          this.getApplePayInfoByCode({
            paymentCode: order.payment_method,
            billno: order.billno,
          }, {
            success: (res) => {
              debuggerLog('ApplePayManager>>>applepayInfo>>>', res)
              if (res && res.code == 0 && res.info) {
                this.applePayInfo = res.info
              } else {
                // TODO 兼容老逻辑，后续等全量后废弃
                // MonitorReportAbnormal.metric({
                //   scene: 'apple_pay_business_id_error',
                //   extraTags: {
                //     failure_type: 'api',
                //     payment_code: order.payment_method,
                //     status_code: res?.code || '',
                //   },
                //   extraParams: {
                //     billno: order.billno,
                //     client_url: '/api/pay/applepayInfo/get',
                //     description: `获取商户号配置接口失败 ${
                //       res?.tips || res?.msg || ''
                //     }`,
                //   },
                // })
                metricReport({
                  scene: 'apple_pay_get_config_error',
                  failure_type: 'api',
                  status_code: res?.code || '',
                  extraParams: {
                    client_url: '/api/pay/applepayInfo/get',
                    description: `获取商户号配置接口失败 ${
                      res?.tips || res?.msg || ''
                    }`,
                  },
                })
                this.onHandlePaymentFail()
              }
            },
            error: (err) => {
              metricReport({
                scene: 'apple_pay_get_config_error',
                failure_type: 'api',
                extraParams: {
                  client_url: '/api/pay/applepayInfo/get',
                  description: `获取商户号配置接口失败 ${
                    err?.message || ''
                  }`,
                },
              })
              this.onHandlePaymentFail(err)
            },
          })
        }
        const request = this.buildApplePaySessionParams(order, cardTypeConfig)
        debuggerLog('applepay request: ', request, 'version', version)
        this.session = new (window as any).ApplePaySession(version, request)
        if (!this.session) {
          metricReport({
            scene: 'apple_pay_get_config_error',
            extraParams: {
              client_url: '/third/sdk/error',
              description: '创建session失败',
            },
          })
          this.onHandlePaymentFail()
          return
        }
        this.session.onpaymentauthorized = this.handlePaymentAuthorized.bind(this)

        this.session.onvalidatemerchant = (event: ApplePayValidateMerchantEvent) => {
          const { applepayKey = '' } = this.applePayInfo || {}
          debuggerLog('ApplePayManager>>onvalidatemerchant>>>>', event)
          this.applePayAuthorizeMerchant({
            merchantIdentifier: applepayKey,
            validateUrl: event.validationURL,
          }).then(data => {
            debuggerLog('ApplePayManager>>merchantSession>>>>', data)
            const merchantSession = data
            // Stop the session if merchantSession is not valid
            if (
              typeof merchantSession === 'string' ||
              'statusCode' in merchantSession
            ) {
              metricReport({
                scene: 'apple_authorizationvc_error',
                extraParams: {
                  client_url: '/third/sdk/error',
                  description: '验证商户号失败',
                  error_stack: JSON.stringify(merchantSession || {}),
                },
              })
              this.cancelPaymentSession()
              return
            }
            if (
              !(
                'merchantIdentifier' in merchantSession &&
                'merchantSessionIdentifier' in merchantSession &&
                ('nOnce' in merchantSession || 'nonce' in merchantSession)
              )
            ) {
              metricReport({
                scene: 'apple_authorizationvc_error',
                extraParams: {
                  client_url: '/third/sdk/error',
                  description: '验证商户号失败',
                  error_stack: JSON.stringify(merchantSession || {}),
                },
              })
              this.cancelPaymentSession()
              return
            }

            if (this.session !== null) {
              this.session.completeMerchantValidation(merchantSession)
            }
          }).catch((err) => {
            debuggerLog('ApplePayManager>>merchantSession>>err>>', err)
            metricReport({
              scene: 'apple_authorizationvc_error',
              extraParams: {
                client_url: '/third/sdk/error',
                description: '验证商户号失败，catch捕获',
                error_stack: JSON.stringify(err || {}),
              },
            })
            this.cancelPaymentSession?.()
          })
        }
        this.session.oncancel = () => {
          metricReport({
            scene: 'apple_pay_payment_cancel',
            extraParams: {
              client_url: '/third/sdk/error',
              description: '渠道返回取消',
            },
          })
          this.onCancel?.()
        }
        this.session.begin()
      } catch (e) {
        metricReport({
          scene: 'apple_pay_payment_session_catch',
          extraParams: {
            client_url: '/third/sdk/error',
            error_msg: (e as any)?.message || '',
            error_stack: e,
          },
        })

        // 二次支付
        if (
          [
            CHECKOUT_TYPE.NORMAL_AGAIN, CHECKOUT_TYPE.BUYPRIME_AGAIN, CHECKOUT_TYPE.GIFTCARD_AGAIN, CHECKOUT_TYPE.XTRA_AGAIN,
          ].includes(this.bsPayConfig?.checkoutType)
        ) {
          //TODO: 弹出二次支付
          ApplePayManager.openDialogMethod && ApplePayManager.openDialogMethod({
            handleOpen: () => {
              metricReport({
                scene: 'apple_pay_payment_opened_dialog',
                extraParams: {},
              })
            },
            handleClose: () => {
              metricReport({
                scene: 'apple_pay_payment_closed_dialog',
                extraParams: {},
              })
              this.onCancel?.()
            },
          })
        } else {
          this.onHandlePaymentFail(e)
        }
      }
    } else {
      console.error('Apple Pay is not supported')
      metricReport({
        scene: 'apple_pay_is_not_supported',
        extraParams: {},
      })
      this.onHandlePaymentFail()
    }
  }

  static initApplePay<T extends IApplePayConfig>(applePayConfig: T) {
    //TODO: 获取阿波罗配置和基础支付相关配置，支付列表包含Apple Pay支付方式执行, 默认值作为兜底
    const { applePayCodeList, applePayTokenChannel, mapChannelToApplePaySupport, cardLogoConfig } = applePayConfig
    this.payConfig.applePayCodeList = applePayCodeList || defaultConfig.applePayChannel
    this.payConfig.cardLogoConfig = cardLogoConfig || {}
    this.payConfig.applePayTokenChannel = applePayTokenChannel || defaultConfig.applePayTokenChannel
    this.payConfig.mapChannelToApplePaySupport = mapChannelToApplePaySupport
    this.payConfig.applePayCashShow = applePayConfig.applePayCashShow
    this.getSupportedCurrentSdkVersion()
    // 清空回调函数
    this.failFns = []
  }

  static bindApplePayDialogMethod(method: ({ handleOpen, handleClose }) => () => void) {
    this.openDialogMethod = method
  }

  async createPayment(): Promise<any> {
    const { relation_billno, billno, paymentCode } = this.unifiedPayParams
    const { currencyCode, countryCode, orderAmount, card_logo_list, sortedPriceInfo, apple_google_support_config_list } = this.payData.channelExtraInfo
    this.handleApplePayPayment({
      order: {
        billno: relation_billno || billno || '',
        currency_code: currencyCode || '',
        countryCode: countryCode,
        payment_method: paymentCode,
        totalPriceAmount: orderAmount || '',
        sorted_price: sortedPriceInfo || [],
      },
      cardTypeConfig: {
        cardLogoList: card_logo_list || [],
        apple_google_support_config_list: apple_google_support_config_list || [],
      },
      preRoutingInfo: {
        applePayMerchantId: this.preRoutingInfo?.merchantInfo?.applePayMerchantId,
        appleCountryCode: this.preRoutingInfo?.merchantInfo?.appleCountryCode || this.preRoutingInfo.orderCountry || countryCode,
      },
    })
  }

}

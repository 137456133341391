import type { C_Libs_Pay } from '../types'
import type { EventBusEvents, EventBusIdentifier } from './hooks/useEventBus'

export const events = new Map<EventBusIdentifier<any>, EventBusEvents<any>>()

export const DEFAULT_APPLPE_PAY_LOCALE: C_Libs_Pay.ApplePayButtonLocale = 'en-US'

// https://developer.apple.com/documentation/apple_pay_on_the_web/applepaybuttonlocale
export const SPECIAL_APPLPE_PAY_LOCALES: { [lang:string]: C_Libs_Pay.ApplePayButtonLocale } = {
  ar: 'ar-AB',
  maar: 'ar-AB',
  arg: 'es-ES',
  cl: 'es-MX',
  enes: 'es-MX',
  es: 'es-ES',
  uses: 'es-MX',
  eurfr: 'fr-FR',
  cafr: 'fr-CA',
  fr: 'fr-FR',
  ma: 'fr-FR',
  de: 'de-DE',
  eurde: 'de-DE',
  ch: 'de-DE',
  'ch-fr': 'fr-FR',
  nl: 'nl-NL',
  eurnl: 'nl-NL',
  au: 'en-AU',
  br: 'pt-BR',
  hk: 'zh-HK',
  id: 'id-ID',
  il: 'he-IL',
  it: 'it-IT',
  mx: 'es-MX',
  pl: 'pl-PL',
  ru: 'ru-RU',
  sesv: 'sv-SE',
  th: 'th-TH',
  tr: 'tr-TR',
  tw: 'zh-TW',
  uk: 'en-GB',
  vn: 'vi-VN',
  'enpt-pt': 'pt-PT',
  'pt-pt': 'pt-PT',
  'eurpt-pt': 'pt-PT',
  'eurcs-cz': 'cs-CZ',
  'eurel-gr': 'el-GR',
  // "eurbg": 'bg',
	asiaja: 'ja-JP',
	asiako: 'ko-KR',
  kr: 'ko-KR',
  // kren: 'en',
	pt: 'pt-PT',
  jp: 'ja-JP',
  // euqs: "en",
  'euqsel-gr': 'el-GR',
  'euqscs-cz': 'cs-CZ',
  'euqsfr': 'fr-FR',
  'euqsnl': 'nl-NL',
  euqsro: 'ro-RO',
  'euqshu': 'hu-HU',
  'euqssk': 'sk-SK',
  'co': 'es-MX',
}
// third party
export const REDIRECT_THIRD_PARTY_PAYMENT_METHODS = [
  'adyen-affirm',
  'adyen-blikdirect',
  'adyen-eps',
  'adyen-fpx',
  'adyen-gcash',
  'adyen-grabpay',
  'adyen-ideal',
  'adyen-mbway',
  'adyen-multibanco',
  'adyen-bancontact',
  'adyen-pix',
  'afterpay-card',
  'afterpay-clearpay',
  'alipay-fpx',
  'alipay-gcash',
  'alipay-grabpay',
  'alipay-kakaopay',
  'alipay-linepay',
  'alipay-naverpay',
  'alipay-pix',
  'checkout-ideal',
  'klarna-sliceit',
  'klarna-klarna',
  'klarna-paylater',
  'routepay-mbway',
  'checkout-multibanco',
  // ''
]

// pc blik pay
export const PC_BLIK_PAY_METHODS = [
  'adyen-blikdirect',
  'alipay-blik',
  'routepay-blik',
]

export const REDIRECT_THIRD_PARTY_PAYMENT_CHANNEL = [
  'ebanx',
  'alipay',
  'afterpay',
  'dlocal',
  'klarna',
  'ksher',
  'nuvei',
  'zip',
  'tabby',
  'scalapay',
  'tamara',
  'ksher',
]

export const REDIRECT_SELF_PAGE_PAYMENT_METHODS = [
  'adyen-boleto',
  'adyen-konbini',
  'ebanx-boleto',
]

export const SPECIAL_SDK_PAYMENT_METHODS = [
  'PayPal-GApaypal',
  'PayPal-Venmo',
  'paidy-paylater',
  'klarna-sliceitdirect',
  'afterpay-cashapp',
]

export const SPECIAL_PAYMENT_TYPES = [
  'applepay',
  'googlepay',
]

export const ABT_POS_KEYS = [
  'ApplepayDropoffOptimization',
  'PaymentCustomizeFlow',
  'CodFreeOptimization',
] as const

export type AbtPosKeysTypes = typeof ABT_POS_KEYS[number]

export const APOLLO_KEYS = [
  'APPLE_PAY_CODE_LIST',
  'APPLE_PAY_CONFIG',
  'GOOGLE_PAY_CODE_LIST',
  'GOOGLE_READY_TO_PAY_REQUEST',
  'PAYMENT_CARD_LOGO_CONFIG',
  'GOOGLE_PAY_CONFIG',
  'PAY_CONFIG_SWITCH',
  'PRE_ROUTING_PAYMENT_METHODS',
  'DLOCAL_PAYMENT_METHODS',
  'FastUnload',
] as const

export const LANG_KEYS_MAP = {
  'LIBS_KEY_PAY_1001': 'SHEIN_KEY_PWA_35147',
  'LIBS_KEY_PAY_1002': 'SHEIN_KEY_PWA_21001',
}

export type LangKeys = keyof typeof LANG_KEYS_MAP

export type ApolloKeysTypes = typeof APOLLO_KEYS[number]
